export const BUSINESS_LAW_TESTIMONIALS = [
	{
		text: 'Used the services offered by Pandev Law to start a limited liability company and the entire experience was seamless to say the least. Very quick follow-ups from the team and they happily answer any and all questions you ask them with a perfect amount of depth. Would highly recommend them for any of your legal needs!',
		name: 'Karan Narang',
		stars: 5,
	},
	{
		text: 'You will be hard-pressed to find any service that is better than Pandev Law. They are extremely professional - the website is easy to navigate while packed with valuable information, you can easily schedule a call, and everyone is very reassuring, helpful, and responsive. Most importantly, they are able to get things done extremely efficient - it took them just about a week to get my LLC all up and running. I highly recommend them!',
		name: 'Khanh Nguyen',
		stars: 5,
	},
	{
		text: 'Adrian is a very helpful professional. He managed to give me advise and reassurance in my case. If and when I require his services in the future I have no doubt I will be turning to him and his team!',
		name: 'M K',
		stars: 5,
	},
	{
		text: 'Adrian and his team consistently provided my digital startup company with amazing representation, from company formation to drafting various critical contracts and agreements. Adrian also provided me with important advice on international deals and U.S. immigration compliance. He took away the stress of legal work off my head and asked me to focus on my business.Adrian is always a pleasure to work with and truly cares about your business as he does about your legal issues. ',
		name: 'Anshul Augnivansh',
		stars: 5,
	},
	{
		text: "Extremely knowledgable and approachable. I would highly recommend Mr. Pandev's services.\r",
		name: 'Kristiyana T Teodosieva',
		stars: 5,
	},
	{
		text: 'Working with Adrian was nothing short of an excellent experience. His attention to detail and quick responses to questions is couple of the many great qualities about him.\nI would highly recommend him to anyone needing his professional expertise.',
		name: 'Pank Vij',
		stars: 5,
	},
	{
		text: 'Adrian is a knowledgeable and professional lawyer who makes time for his clients and offer them with the best advice. I highly recommend his professional services.',
		name: 'Shoaib Iqbal',
		stars: 5,
	},
	{
		text: 'Adrian was absolutely wonderful to work with. It was so very clear that he cared about my case and he brought a balance of strong legal advice and conversational troubleshooting to fit the specificity of my situation. I waited till after we completed a retainer agreement before writing this, as I noticed that some of the reviews are for the initial consultation. Having worked with him and his team through setting up my business in compliance with legal and immigration laws, I would 10/10 highly recommend his service!',
		name: 'Aniekeme Umoh',
		stars: 5,
	},
	{
		text: 'Pandev Law helped me and my partner form our new LLC.  They we’re responsive and professional and got the job done!  Wouldn’t hesitate to have them represent me in the future!',
		name: 'Andrew Marchetti',
		stars: 5,
	},
];
