import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import get from 'lodash.get';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/stock-exchange.jpg';
import CtaDeck from '../components/common/cta-deck';
import { BUSINESS_LAW_TESTIMONIALS } from '../data/business-law-services/testimonials';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'Business Law | Startup Law | Customs Law | Pandev Law',
  description:
    'Searching for a trusted and experienced Business Law Firm? Pandev Law is here to help with your business, startup, and customs law questions. Talk to us now!',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Business Law | Startup Law | Customs Law | Pandev Law",
    "description": "Searching for a trusted and experienced Business Law Firm? Pandev Law is here to help with your business, startup, and customs law questions. Talk to us now!",
    "url": "https://www.pandevlaw.com/business-law-services",
    "image": "https://pandevlaw.com/img/stock-exchange.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/business-law-services/"
  },
  "headline": "Business Law",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function BusinessLaw({ location, data }) {
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title="Business Law" img={heroImg}>
        <p className="text-lead pt-5">
          I am committed to helping your business achieve its goals locally and on a
          global level. I provide custom strategies that help your company navigate the
          legal and regulatory complexities of operating a business in the US.
          <br />
          <br />I regularly advise entrepreneurs, startups, small and medium sized
          companies in business transactions, including formation, financing, and complex
          commercial agreements. A particular niche of mine is representing foreign
          companies and entrepreneurs who are opening a US subsidiary or are starting a
          new US enterprise. In this regard, in addition to corporate representation, I
          also advise on customs and trade laws, including import and export compliance.
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Business Law Testimonials"
        testimonials={BUSINESS_LAW_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/hands-with-pencil.jpg"
                alt="Company formation and corporate governance law"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">Corporate Law</h2>
              <p className="text-body mb-6">
                Whether you are an entrepreneur starting a new venture or an established
                company looking for experienced business counsel, I understand your
                company’s business needs. I offer strategic advice to help you achieve
                your business objectives and help mitigate associated risks. I offer a
                wide variety of corporate legal services tailored to your specific
                business needs. My business and corporate law services include:
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true" // eslint-disable-line
                      aria-controls="corporateLawCollapseOne">
                      <strong>Company Formation</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      Efficient business operation and asset protection are just some of
                      the many critical considerations in choosing the right legal entity
                      for your business. I have the experience to guide you through this
                      decision and structure your company to meet your needs. Entities I
                      help form, include:
                    </p>
                    <ul className="list-bullets">
                      <li>Limited Liability Companies</li>
                      <li>Corporations</li>
                      <li>Partnerships</li>
                      <li>Nonprofit Organizations (Section 501(c)(3) Organization)</li>
                      <li>New York Benefit Corporations</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseTwo"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="corporateLawCollapseTwo">
                      <strong>Corporate Governance</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingTwo"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      I draft, review, and ensure maintenance of your important internal
                      company documents, including:
                      <ul className="list-bullets mt-4">
                        <li>Shareholder Agreements</li>
                        <li>Partnership Agreements</li>
                        <li>Operating Agreements</li>
                        <li>Shareholder and Member Resolutions</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseThree"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="corporateLawCollapseThree">
                      <strong>Contract Drafting, Review, and Negotiation</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseThree"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingThree"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      I negotiate and provide legal advice regarding various commercial
                      agreements, including:
                      <ul className="list-bullets mt-4">
                        <li>Asset Purchase Agreements</li>
                        <li>Assignment Contracts</li>
                        <li>Commercial Leases & Subleases</li>
                        <li>Dissolution and Buyout Agreements</li>
                        <li>Employment Agreements</li>
                        <li>Financing Agreements (Debt & Equity)</li>
                        <li>Franchise Agreements</li>
                        <li>
                          Freelancer Agreements (Compliant with NYC Freelance Isn’t Free
                          Act)
                        </li>
                        <li>Independent Contractor Agreements</li>
                        <li>Joint Venture Agreements</li>
                        <li>Licensing Agreements</li>
                        <li>Non-Disclosure Agreements</li>
                        <li>Non-Competition Agreements</li>
                        <li>Partnership Agreements</li>
                        <li>Shareholder Agreements</li>
                        <li>Stock Purchase and Sale Agreements</li>
                        <li>Trade Agreements</li>
                        <li>Voting Agreements</li>
                        <li>Merger Agreements</li>
                        <li>Operating Agreements</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingFour">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseFour"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="corporateLawCollapseFour">
                      <strong>Buy or Sell a Business</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseFour"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingFour"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      Purchasing or selling a business is often a complex process
                      requiring extensive planning and due diligence. You could accrue
                      significant expenses if the deal is not structured properly. I use
                      my experience to help guide you through the business sales or
                      acquisition process. My services, include:
                      <ul className="list-bullets mt-4">
                        <li>
                          <b>Develop Transaction Strategy and Structure</b>
                        </li>
                        <li>
                          <b>Due Diligence,</b>including careful review of business books,
                          records, and assets to discover potential risks and liabilities
                          to you or your business.
                        </li>
                        <li>
                          <b>Pre-Negotiation Preparation,</b>
                          including careful planning of what exactly is being bought or
                          sold. For example, you may want to retain certain real or
                          intellectual property assets after the sale of your company. In
                          this case, it is critical to plan ahead and ensure that you
                          communicate to the other party that these assets are not part of
                          the transaction.
                        </li>
                        <li>
                          <b>Negotiation of Transaction Terms</b>
                        </li>
                        <li>
                          <b>Contract Drafting and Review,</b>
                          including Asset Purchase Agreements, Stock Purchase Agreements,
                          Bills of Sale, Intellectual Property Assignments, etc.
                        </li>
                        <li>
                          <b>Internal Corporate Compliance</b>, including Corporate
                          Resolutions, Closing Obligations, Name Changes, and various
                          other internal corporate cleanup documents.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <div id="corporateLawHeadingFive">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseFive"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="corporateLawCollapseFive">
                      <strong>Business Disputes</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseFive"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingFive"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      I use my experience gained as a Trial Attorney at the US Department
                      of Justice to represent companies, business owners, and freelancers
                      in business disputes, including:
                      <ul className="list-bullets mt-4">
                        <li className="mt-2">
                          <strong>Business Divorce</strong> – Ending a long-term business
                          relationship is always challenging and may cause disruption to
                          your business. I negotiate settlements and separation plans so
                          that you or your unwanted business partners can exit the company
                          with minimal disruption to the business. I draft Buyout
                          Agreements, Dissolution Agreements, Withdrawal Agreements, and
                          represent you in arbitration, mediation, or court proceedings.
                        </li>
                        <li className="mt-2">
                          <strong>Business Litigation</strong> – Did your service provider
                          fail to perform the services for which you have paid? Did your
                          corporate officer misuse company funds or engage in
                          self-dealing? Are you owed money and continue to be ignored by
                          the individual who owes you? I use my experience as a federal
                          litigator to advocate for you before various courts and
                          administrative tribunals. My business litigation practice
                          includes:
                          <ul className="list-bullets mt-3">
                            <li className="mt-2">Sending Demand Letters</li>
                            <li className="mt-2">Enforcing Money Judgments</li>
                            <li className="mt-2">Breach of Contract Claims</li>
                            <li className="mt-2">Breach of Fiduciary Duty Claims</li>
                            <li className="mt-2">Shareholder Dispute Litigation</li>
                          </ul>
                        </li>
                        <li className="mt-2">
                          <strong>Freelancer Disputes</strong> – I represent Freelancers
                          and businesses employing Freelancers in claims under New York
                          City’s Freelance Isn’t Free Act, the nation’s first legislation
                          protecting Freelancers from non-payment. If you are a Freelancer
                          who has not been paid for your work, the Freelance Isn’t Free
                          Act allows you to collect your attorneys’ fees and costs for
                          filing your claim in addition to the monetary damages you may be
                          entitled to.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/containers.png"
                alt="International business law"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">International Business Law</h2>
              <p className="text-body mb-6">
                In today’s global world, businesses operate across borders and face
                complex regulatory challenges. I am committed to helping your business
                achieve its goals on a global level. I provide customized strategies that
                help your business navigate the legal and regulatory complexities of
                international finance and trade. As your international business lawyer, I
                advise you on various international business transactions, including
                customs compliance, subsidiary formation, financing, capital formation,
                acquisitions, and other matters related to foreign direct investment. I
                serve as your trusted counsel throughout the entire life cycle of a
                transaction. I speak Russian, German, Hungarian, and Bulgarian fluently
                and always cherish the opportunity to advise you in your native language.
              </p>
              <div className="accordion" id="internationalBusinessLaw">
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseOne"
                      aria-expanded="true" // eslint-disable-line
                      aria-controls="internationalBusinessLawCollapseOne">
                      <strong>Customs &amp; International Trade Law</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="internationalBusinessLawHeadingOne"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body mt-4 mb-5">
                      In today’s global world, businesses of all sizes are thinking about
                      expanding internationally. With increased enforcement and trade
                      regulation worldwide, doing business across borders is becoming more
                      and more challenging. My customs and international trade practice
                      focuses on advising importers and exporters regarding US customs
                      compliance. I represent companies in various customs compliance
                      matters, including product classification and valuation,
                      investigations, audits, country of origin marking, and litigation
                      related to alleged customs violations. My customs and international
                      trade law services include:
                      <br />
                      <br />
                      <strong>Inbound US Customs and Import Services</strong>– I serve as
                      your trusted legal advisor throughout the entire importing process.
                      I counsel you regarding:
                    </p>
                    <ul className="list-bullets">
                      <li>Country of Origin Marking</li>
                      <li>Customs Valuation</li>
                      <li>Duty Reduction</li>
                      <li>Import and Trade Strategies</li>
                      <li>Internal Customs Compliance Programs</li>
                      <li>Litigation before US Court of International Trade</li>
                      <li>
                        Preference Programs and Trade Agreement Eligibility Evaluation
                      </li>
                      <li>Representation in Audits and Investigations</li>
                      <li>Restrictive Quota Strategies</li>
                      <li>Seizure of Goods</li>
                      <li>Tariff Classification</li>
                    </ul>
                    <p className="text-body mt-4 mb-5">
                      <strong>Outbound US Customs and Export Services</strong>– I provide
                      legal advice on compliance and guide you through trade regulations
                      governing exports, including:
                    </p>
                    <ul className="list-bullets">
                      <li>Classification Rulings</li>
                      <li>Export Control</li>
                      <li>Export Seizures</li>
                      <li>Export Strategy and Planning</li>
                      <li>Internal Compliance Programs and Audits</li>
                      <li>Representation in Investigations</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseTwo"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="internationalBusinessLawCollapseTwo">
                      <strong>Cross-border Transactions</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingTwo"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      If your business operates across international borders, you need
                      legal representation that understands cross-border transactions. I
                      represent foreign and domestic clients in international agreements
                      and transactions. I provide legal advice focused on helping protect
                      your cross-border interests. I regularly work with trusted foreign
                      counsel on international transactions to ensure effective
                      representation throughout the lifecycle of the deal. I provide
                      representation in various cross-border matters, including:
                      <ul className="list-bullets mt-4">
                        <li>Capital Formation</li>
                        <li>Cross-Border Financing</li>
                        <li>Cross-Border Service Agreements</li>
                        <li>Foreign Direct Investment</li>
                        <li>International Licensing</li>
                        <li>International Purchases and Acquisitions</li>
                        <li>International Project Finance</li>
                        <li>International Trade</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseThree"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="internationalBusinessLawCollapseThree">
                      <strong>Foreign Direct Investment</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseThree"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingThree"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      Are you looking to expand your existing business to the US or start
                      a new venture in the US? An international expansion can be a
                      complicated process requiring extensive due diligence and
                      transaction planning. Failure to properly plan and structure all
                      transactions could lead to significant expenses and compliance
                      issues for your business. I have represented many foreign companies
                      and entrepreneurs opening a new US subsidiary or company. I advise
                      you throughout the entire investment process, from the initial
                      planning stages until your US business is fully operational. My
                      foreign direct investment services include:
                      <ul className="list-bullets mt-4">
                        <li>Company Formation</li>
                        <li>Compliance with Laws and Regulations</li>
                        <li>Contract Drafting and Review</li>
                        <li>Customs & Trade Law</li>
                        <li>Immigration Solutions</li>
                        <li>Mergers, Acquisitions, and Joint Ventures</li>
                        <li>Negotiation of Transaction Terms</li>
                        <li>Recapitalization</li>
                        <li>Stock Purchase and Sales</li>
                        <li>Transaction Planning, Preparation, and Structuring</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <div id="internationalBusinessLawHeadingFour">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseFour"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="internationalBusinessLawCollapseFour">
                      <strong>Offshore Company Formation</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseFour"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingFour"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      I have experience with comprehensive offshore incorporation and
                      business formation strategies. “Going offshore” may have several
                      advantages for your business, especially if you own an e-commerce,
                      online, or other location independent business. These advantages
                      include:asset protection, confidentiality, estate planning benefits,
                      tax liability reduction, and less restrictive business regulations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/people-with-computer.png"
                alt="Startup law"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Startup Law</h2>
              <p className="text-body mb-6">
                I am passionate about helping startup companies with their legal needs.
                Pandev Law was once a startup. I have personally experienced the
                challenges and hurdles associated with starting your own business. I
                understand your needs as an entrepreneur. I offer legal advice to help
                your startup business grow while remaining compliant with all laws and
                regulations. My startup legal services include:
              </p>
              <div className="accordion" id="startupLaw">
                <div className="mb-5">
                  <div id="startupLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#startupLawCollapseOne"
                      aria-expanded="true" // eslint-disable-line
                      aria-controls="startupLawCollapseOne">
                      <strong>Flat Fee Company Formation Package</strong>
                    </h2>
                  </div>
                  <div
                    id="startupLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="startupLawHeadingOne"
                    data-parent="#startupLaw">
                    <p className="text-body mt-4 mb-5">
                      I understand that starting a new business can be expensive and that
                      most entrepreneurs do not have a large budget for legal expenses.
                      Keeping this in mind, I have created a flat fee company formation
                      package specifically tailored to the needs of new businesses. My
                      flat fee company formation package provides you with important
                      liability protection at a reasonable price. This allows you to
                      invest your money where it belongs, your business. My flat fee
                      company formation package includes:
                    </p>
                    <ul className="list-bullets">
                      <li className="mb-4">
                        Consultation with a business attorney to discuss your goals and to
                        determine the best entity type for your business needs.
                      </li>
                      <li className="mb-4">
                        Advice on selecting a company name compliant with Secretary or
                        Department of State rules.
                      </li>
                      <li className="mb-4">
                        <p className="mb-4">
                          Draft and file relevant documents, including:
                        </p>
                        <ul>
                          <li>
                            <p>
                              <strong>For a Corporation:</strong>
                            </p>
                            <ul className="list-bullets mb-4">
                              <li>Articles or Certificates of Incorporation</li>
                              <li>By-Laws</li>
                              <li>Shareholder Agreement</li>
                              <li>Initial Resolutions (Director and Shareholder)</li>
                              <li>Subscription Agreement</li>
                            </ul>
                          </li>
                          <li>
                            <p>
                              <strong>For a Limited Liability Company (LLC</strong>
                              ):
                            </p>
                            <ul className="list-bullets mb-4">
                              <li>Articles of Organization</li>
                              <li>LLC Operating Agreement</li>
                              <li>Initial Resolution (Members and/or Managers)</li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="mb-4">
                        Obtain EIN (Federal Tax Identification Number).
                      </li>
                      <li className="mb-4">If necessary, file S-Corporation election.</li>
                      <li className="mb-4">
                        Informational package summarizing important requirements for
                        maintaining limited liability. The package also includes relevant
                        general information on how to open a business bank account, and
                        how to obtain a lower cost professional business address or a
                        shared or virtual office.
                      </li>
                      <li>
                        All filing fees, including state filing fees and registered agent
                        fees, are included in the flat fee price to assist you with
                        financial planning and budgeting.
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div id="startupLawHeadingTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#startupLawCollapseTwo"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="startupLawCollapseTwo">
                      <strong>Additional Startup Law Services</strong>
                    </h2>
                  </div>
                  <div
                    id="startupLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="startupLawHeadingTwo"
                    data-parent="#startupLaw">
                    <div className="mt-4">
                      <li className="mt-2">General Counsel Services</li>
                      <li className="mt-2">Corporate Governance & Maintenance</li>
                      <li className="mt-2">Technology Law Services</li>
                      <li className="mt-2">
                        Employee and Independent Contractor Agreements
                      </li>
                      <li className="mt-2">
                        Purchase or Sale Agreements to Buy or Sell a Business
                      </li>
                      <li className="mt-2">
                        Financing (including Private Equity and Venture Capital
                        Transactions, Stock Sales and Purchases, Secured and Unsecured
                        Loans, etc.)
                      </li>
                      <li className="mt-2">Commercial Leases and Subleases</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/people-walking.png"
                alt="General counsel"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">General Counsel</h2>
              <p className="text-body mb-4">
                If you are running a small or medium sized company, you know that legal
                questions frequently arise in the day to day operation of a business.
                Virtually any important business decision you make could have significant
                legal consequences. While a large company may have an in-house legal
                department, small business owners often have to resolve legal issues on
                their own. This often takes valuable time away from growing your company.
                As your outside general counsel, I handle your legal issues while you
                focus on running your business. I serve as outside general counsel to
                businesses of all sizes.
                <br />
                <br />
                <strong>How does it work?</strong>
                For a monthly flat fee retainer I am available to answer legal questions
                and represent you in various legal matters, including negotiating
                transactions, advising on contractual matters, and providing guidance on
                dispute resolution.
                <br />
                <br />
                <strong>What is the benefit?</strong>
                At a fraction of regular hourly rates and significantly below the cost of
                hiring a fulltime in-house attorney, you have a trusted business counselor
                who you can contact at any time regarding your legal issues.
                <br />
                <br />
                <strong>How much does it cost?</strong>
                The rates vary depending on the size and complexity of your business, and
                the amount and type of legal advice and representation you need. Fees can
                range from as little as few hundred to several thousand dollars a month.
                Your monthly rate can be adjusted depending on your utilization rate. I
                tailor my services to fit your business goals and needs.
                <br />
                <br />
                Whether you have general business related legal questions, are considering
                opening a new office, deciding whether to buy or sell your business, or
                need representation in contract drafting or negotiation, I act as your
                experienced outside general counsel. I help you achieve your business
                objectives and protect you from unnecessary risks. My outside business
                general counsel services include:
              </p>
              <ul className="list-bullets">
                <li className="mb-4">
                  <strong>Unlimited Consultations</strong> with your General Counsel who
                  is familiar with your business needs and objectives, can identify
                  important legal issues, and guide you through critical strategic
                  decisions.
                </li>
                <li className="mb-4">
                  <strong>Compliance with Laws and Regulations</strong>
                </li>
                <li className="mb-4">
                  <strong>Contract Drafting and Review</strong>, including Service and
                  Vendor Contracts, Independent Contractor Agreements, Buy and Sell
                  Agreements, General Business Contracts, and Debt and Equity Financing
                  Agreements.
                </li>
                <li className="mb-4">
                  <strong>Negotiating and Advising on Transactions</strong>
                </li>
                <li className="mb-4">
                  <strong>Corporate Governance and Maintenance</strong>, including
                  important internal company documents, such as Shareholder Agreements,
                  Partnership Agreements, Operating Agreements, and Shareholder
                  Resolutions.
                </li>
                <li>
                  <strong>Business Dispute Resolution</strong>
                </li>
              </ul>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/woman-with-tablet.png"
                alt="Business law services USA"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Technology Law</h2>
              <p className="text-body mb-4">
                Whether you are a tech innovator in charge of an early phase startup
                company or an established tech company in the final stages of development,
                I use my knowledge and experience to help your technology business grow
                and achieve its objectives. I have the experience to guide you through
                company formation, product development, and distribution processes to make
                your product accessible to a global audience. I often collaborate with
                experienced patent prosecutors and intellectual property attorneys to
                ensure the highest level of intellectual property services. My technology
                law services include:
              </p>
              <ul className="list-bullets">
                <li>Capital Raises</li>
                <li>Cease and Desist Letters</li>
                <li>Company Formation</li>
                <li>Copyright Registration</li>
                <li>Corporate Governance</li>
                <li>Employee &amp; Independent Contractor Agreements</li>
                <li>Financing Agreements</li>
                <li>General Counsel Services</li>
                <li>Joint Venture Agreements</li>
                <li>Non-Disclosure Agreements</li>
                <li>Private Equity Financing</li>
                <li>Purchase & Sales Agreements</li>
                <li>Service Contracts</li>
                <li>Stock Offering</li>
                <li>Software Licensing Agreements</li>
                <li>Venture Capital Transactions</li>
                <li>Trademark Registration</li>
                <li>Website Terms, Conditions, and Privacy Policies</li>
              </ul>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/hands-with-phone.png"
                alt="Business law"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                Blockchain and Cryptocurrency Law
              </h2>
              <p className="text-body mb-6">
                I am fascinated by the potential blockchain technology presents for
                businesses of all sizes. I am confident that the blockchain will forever
                change how international business and cross-border transactions are
                conducted. I collaborate with blockchain industry leaders, including
                experienced intellectual property and securities counsel, to help
                blockchain entrepreneurs take advantage of the tremendous opportunities in
                this emerging field. I can help you navigate the uncertain regulatory
                environment of blockchain technology whether you are new to the world of
                blockchain or already established in the industry. I represent investors
                and innovators in various blockchain matters, including smart contracts,
                cryptocurrency, mining, and cryptocurrency ATM networks. My blockchain and
                cyrptocurrency services include:
              </p>
              <div className="accordion" id="cryptocurrencyLaw">
                <div className="mb-5">
                  <div id="cryptocurrencyLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#cryptocurrencyLawCollapseOne"
                      aria-expanded="true" // eslint-disable-line
                      aria-controls="cryptocurrencyLawCollapseOne">
                      <strong>Compliance with Laws and Regulations</strong>
                    </h2>
                  </div>
                  <div
                    id="cryptocurrencyLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="cryptocurrencyLawHeadingOne"
                    data-parent="#cryptocurrencyLaw">
                    <p className="text-body mt-4 mb-5">
                      Regulators are initiating more and more enforcement actions against
                      blockchain and crypto companies, including smart contract
                      developers, mining companies, and cryptocurrency ATM operators. ICOS
                      and crypto funds are also receiving more regulatory scrutiny than
                      ever before. If you are involved in this space, it is critical that
                      you ensure your offerings are compliant with all laws and
                      regulations. I help you evaluate your company’s potential liability
                      exposure, including liability under US Commodity Futures Trading
                      Commission (CFTC) and US Securities and Exchange Commission (SEC)
                      regulations, and various banking laws. I also advise you on
                      establishing internal compliance standards and procedures. In this
                      regard, I represent:
                    </p>
                    <ul className="list-bullets">
                      <li>Smart Contract Developers</li>
                      <li>Mining Companies</li>
                      <li>Initial Coin Offerings (ICOs)</li>
                      <li>Private ICO and Crypto Funds</li>
                      <li>Cryptocurrency ATM Networks and Operators</li>
                      <li>Blockchain Companies and Developers</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="cryptocurrencyLawHeadingTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#cryptocurrencyLawCollapseTwo"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="cryptocurrencyLawCollapseTwo">
                      <strong>Business &amp; Corporate Law</strong>
                    </h2>
                  </div>
                  <div
                    id="cryptocurrencyLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="cryptocurrencyLawHeadingTwo"
                    data-parent="#cryptocurrencyLaw">
                    <div className="mt-4">
                      I use my business law experience to serve as your trusted corporate
                      counsel advising on various aspects of your blockchain or crypto
                      business. My business law services for blockchain companies include:
                      <ul className="list-bullets mt-4">
                        <li>Company Formation</li>
                        <li>Corporate Governance</li>
                        <li>Employee and Independent Contractor Agreements</li>
                        <li>Financing Agreements</li>
                        <li>General Counsel Services</li>
                        <li>Joint Venture and Partnership Agreements</li>
                        <li>Licensing Agreements</li>
                        <li>
                          Intellectual Property Protection Agreements (including
                          Non-Compete and Non-Disclosure Agreements)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <div id="cryptocurrencyLawHeadingThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#cryptocurrencyLawCollapseThree"
                      aria-expanded="false" // eslint-disable-line
                      aria-controls="cryptocurrencyLawCollapseThree">
                      <strong>Investor Due Diligence</strong>
                    </h2>
                  </div>
                  <div
                    id="cryptocurrencyLawCollapseThree"
                    className="collapse"
                    aria-labelledby="cryptocurrencyLawHeadingThree"
                    data-parent="#cryptocurrencyLaw">
                    <div className="mt-4">
                      I conduct due diligence investigations for individual investors and
                      venture capital, crypto, and ICO funds, to discover potential risks
                      and liabilities associated with investment in a particular
                      blockchain or crypto company. My representation includes standard
                      pre-investment review of business assets and books, and regulatory
                      compliance review ensuring that the offering complies with various
                      state and federal laws, and agency regulations. I also confirm the
                      company has registered with relevant regulatory agencies.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Business Law Testimonials"
        testimonials={BUSINESS_LAW_TESTIMONIALS}
      />

      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default BusinessLaw;

export const query = graphql`
  query BusinessLawQuery {
    category: wpCategory(slug: { eq: "business-law" }) {
      ...BlogLandingHeroPost
    }
  }
`;
